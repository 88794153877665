/* ==========================================================================
   Blanc Vs. DioBrum - SoulFood
   ========================================================================== */

/* Boilerplate */

$default-em: 18;
$brand: #78b22b;

@function em($pixels, $context: $default-em) {
    @return #{$pixels/$context}em;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

html {
    background: $brand;
}

img { border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}


/* ==========================================================================
   fun background
   ========================================================================== */

html {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#bg {
    position:fixed;
    top:-50%;
    left:-50%;
    width:200%;
    height:200%;
    z-index: -100;
}

#bg img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    min-width:50%;
    min-height:50%;
}

body {
    font-size: 1em;
}

/* ==========================================================================
   Main styles
   ========================================================================== */

body {
    font-family: 'Asap', sans-serif;
    font-weight: 400;
    font-size: 1em;
    line-height: 26/19;
}

.flatpage {
    background: url(../img/cards.png) 50px 400px no-repeat;
}

header {
    margin: 2em;
    top: 0;
    left: 0;
    padding: 0;

    h1 {
        margin: 0;
        padding: 0;
        display: block;
        font-size: 1.75em;
        a {
            font-size: 1.5em;
            color: #fff;
            text-decoration: none;
            font-weight: normal;
            text-transform: lowercase;
        }
    }
}

.food {
    font-style: italic;
    &:before { content: "["; font-style: normal; }
    &:after  { content: "]"; font-style: normal; }
}

header .food {
    &:before { content: "["; font-style: normal; }
    &:after  { content: "]"; font-style: normal; }
}

h2 {
    display: block;
    clear: both;
    font-size: 3em;
    &:before { content: "["; font-size: 1.2em; opacity: 0.8 }
    &:after { content: "]"; font-size: 1.2em; opacity: 0.8 }
    text-align: center;
    color: #fff;
    margin: 1em auto;
    text-transform: lowercase;
    font-weight: normal;
}

article {
    position: relative;
    z-index: 1;
    width: 30em;
    margin: 5em auto 10em auto;
    background: rgba(0,0,0,0.5);
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
    padding: 0.5em 2em;
    color: #fff;
    font-weight: normal;

    &.nobottom { margin-bottom: 1em; }

    h2 {
        position: absolute;
        margin-top: -1.8em;
    }

    p {
        line-height: 140%;
    }

    a { color: $brand; }

    form {
        label {
            left: -999em;
            position: absolute;
        }
    }
}

input, textarea, button {
    width: 100%;
    box-sizing: border-box;
    font-size: 1em;
    padding: em(8.5) em(9.5);
}

input[type=checkbox] {
    margin: 0;
    width: auto;
    position: absolute;
    top:.25em;
    left:0
}

form p {
    position: relative;
}

label[for=id_agree_terms] {
    display: block;
    position: static;
    margin-left: 1.5em;
    margin-right: 1em;
}

button {
    margin: auto;
    background: transparent;
    color: #fff;
    border: none;
    width: auto;
    margin-bottom: em(20);
    line-height: 2em;
    font-size: em(22);
    position: relative;
    left: 50%;
    font-family: 'Asap', sans-serif;
    transform: translateX(-50%);
    &:before { content: "["; font-style: normal; font-size: 2em; position: relative; top: 4px; left: -5px}
    &:after  { content: "]"; font-style: normal; font-size: 2em; position: relative; top: 4px; left: 5px}
}

textarea {
    height: 7em;
    resize: vertical;
}

#payment_form label {
    display: block;
    position: relative;
    left: 0;
}

.c {
    display: block;
    background: #fff;
    width: 20px;
    height: 10px;
    position: absolute;
    &.c1 {top: -10px;  left: -10px;}
    &.c2 {top: -10px; right: -10px;}
    &.c3 {bottom: -10px;  left: -10px;}
    &.c4 {bottom: -10px; right: -10px;}
}

footer {
    margin-top: em(50);
    padding: em(20);
    padding-bottom: 16em;
    font-size: 0.8em;
    min-height: 66px;
    color: #fff;
    text-align: right;
    background: url(../img/strapline.png) center em(20) no-repeat;
    img {
        float: left;
    }
}

header {
    background: url(../img/devices.png) right top no-repeat;
    min-height: 64px;
}

form.map-search {
    padding: em(20);
    background: #343538;
    color: #fff;
    overflow: hidden;
    p {
        display: inline-block;
        margin: 0;
    }
    input {
        margin-left: em(10);
        box-sizing: border-box;
        width: 100%;
        font-size: 1em;
        border: 1px solid #000;
        padding: em(8.5) em(9.5);
    }

    button {
        font-weight: 600;
        font-size: em(18);
        text-decoration: none;
        border: 1px solid #000;
        background: $brand;
        color: #fff;
        border: none;
        background: none;
        margin: 0;
        display: inline-block;
        float: right;
        position: relative;
        top: -7px;
        left: 0;
        transform: none;
        padding: 0;
        &:hover {
            cursor: pointer;
        }
    }
}



/* ==========================================================================
   Modernizr
   ========================================================================== */
.backgroundsize #bg { display: none; }

/* landing list */

.campaign_list {
    &, body {background: $brand; margin: 0; padding: 0; }

    #bg { display: none; }

    ul {
        margin: 2em auto 0 auto;
        padding: 0;
        text-align: center;
        list-style: none;
        overflow: hidden;
        position: relative;

        li {
            position: relative;
            display: inline-block;
            list-style: none;
            margin: 20px;
            border-left: 10px solid #fff;
            border-right: 10px solid #fff;
            width: 30%;
            max-width: 320px;

            a, .inactive {
                display: block;
                line-height: 240px;
                text-align: center;
                color: #fff;
                font-style: italic;
                font-size: 170%;
                text-decoration: none;
                background: rgba(0,0,0,0.5);
            }
        }
    }
    small {
        position: relative;
        float: left;
        display: block;
        line-height: 1;
        margin-top: -3em;
        font-size: 0.5em;
        width: 100%;
        text-transform: uppercase;
        font-style: normal;
        text-align: center;
    }
}

.mainnav {
    display: block;
    margin: -3.5em auto 1em auto;
    font-size: 1.4em;
    text-align: center;
    text-decoration: italic;
    a {
        text-decoration: none;
        color: #fff;
        font-style: italic;
        margin: 0 0.3em;
        &:hover, &.active {
            border-bottom: 2px solid #fff;
            padding-bottom: 2px;
        }
    }
}

#mc_embed_signup {
    background: none !important;
    color: #fff;
    .mc-field-group label { position: static; left: 0; }
    .mc-field-group .asterisk { top: -1px !important; }
    .button { background-color: rgb(120, 178, 43) !important; &:hover { background-color: darken(rgb(120, 178, 43), 10%) !important;} }
}

html.no-rgba article {
    background: rgb(59, 90, 14);
}

@media (max-width:840px) {
    .campaign_list ul li { width: 80%; }
    .mainnav { margin-top: 1em; }
    article { width: auto; margin-left: 10px; margin-right: 10px; }
    body { padding-bottom: 30px; }
    article h2 { width: auto; text-align: center; position: static; }
    footer { background-position: center 110px; }
}

@media (max-width:460px) {
    /*.campaign_list h1 { font-size: 1.5em; text-align: center; }*/
    footer {background: none;}
    header { background: none; }
    .flatpage { background: none;}
    form.map-search {
        button {
            top: 2px;
            left: -4px;
        }
        p {
            display: block;
            #id_postcode {
                width: 100%;
                margin: em(5) 0 0 0;
            }
        }
    }
}

.email-campaign-embed {
    overflow: hidden;
    padding: 0 !important;
    text-align: center;
    header, nav, .food, footer, .c {
        display: none;
    }
    p {
        text-align: left;
    }
    button {
        left: auto;
        transform: none;
    }
    article {
        display: inline-block;
        border: none;
        margin: 0;
        .food {
            display: inline;
        }
    }
    .right {
        float: right;
        opacity: 0.35;
        color: #fff;
        &:hover {
            opacity: 0.7;
        }
        a {
            color: #fff;
        }
    }
}


/* ==========================================================================
   Map iframe
   ========================================================================== */

.map-canvas {
    display: block;
    width: 100%;
    height: 640px;
    margin: 0 auto;
}

.popup-content {
    color: black;
    h2 {
        position: static;
        font-size: 18px;
        text-align: left;
        text-transform: none;
        margin: 0;
        color: black;
        &:before, &:after { display: none; }
    }
    p { margin-top: 0; }
    a {
        display: block;
        font-weight: bold;
        text-align: left;
        text-decoration: none;
        color: $brand;
        &:before { content: '['; }
        &:after { content: ']'; }
    }
}

#id_postcode {
    @media all and (min-width: 460px) {
        width: 12em;
    }
}

.result-count {
    padding: em(20);
    background: #fff;
    color: $brand;
    margin: 0;
    span {
        font-weight: 800;
    }
}

ul.results {
    list-style: none;
    padding-left: 0;
    margin: em(20) 0;
    overflow: hidden;
    li {
        box-sizing: border-box;
        padding: em(20) em(20) em(20) em(55);
        border-bottom: 1px dotted ($brand + #222);
        position: relative;
        &:last-child {
            border-bottom: none;
        }
        h3, h6 {
            margin: 0;
        }
        h3 {
            font-weight: 600;
        }
        h6 {
            font-size: em(20);
            position: absolute;
            line-height: 1.2;
            top: em(20);
            left: em(15);
        }
        p {
            margin: 0;
            &:first-child {
                font-size: em(14);
                text-transform: uppercase;
            }
            &:last-child {
                margin-top: em(10);
                margin-bottom: 0;
                a {
                    color: black;
                    font-weight: 600;
                }
            }
        }
        &:nth-child(odd) {
            border-right: 1px dotted ($brand + #222);
        }
        @media all and (min-width: 830px) {
            width: 50%;
            float: left;
        }
    }

}
